<app-menu></app-menu>

<div class="main-banner-area">
    <div class="home-sliders owl-carousel owl-theme">
        <div class="home-item item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Make Real-Life Connections With Askdev</h1>
                            <p>Our website builder is not only easy to use – it's mighty powerful too. No coding or design skills needed. Get started in minutes.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/image-1.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Software & Digital Development</h1>
                            <p>Welcome to your one stop digital solution.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/image-2.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Digital Agency & Marketing</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing incididunt ut laboredolore magna aliqua elsed tempomet, consectetur adipiscing.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/image-3.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Discover</h3>
                    <p>Defining milestones, real potential of your idea through digital transformation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Great Design</h3>
                    <p>Creating a responsive and user-experience for your customers as well as partners.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>Develop a demo app with customized requirements as per client needs, and specified by tech team.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Security</h3>
                    <p>A rigorous testing procedure that eliminate major/minor bug and glitches to improve the performance and security.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Deploy</h3>
                    <p>Launching your app to the market, bring your App to live by deploy on server, web, iOS, and Android.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Support</h3>
                    <p>Extensive support from ASKDEV solutions, 24/7 support from our team to your app/product. Within 24hours the response.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="creative-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>Our Business <span>Approach</span></h3>
                    <div class="bar"></div>
                    <p>ASKDEV solution has experienced team in the industry to create a responsive user-friendly App for web and mobile. Successful product development requires a balanced approach. We blend personalized, in-depth technical guidance with
                        our global scale. By leveraging Agile development methodologies, human-centered design tools, and best practices around security and risk management, we help organizations meet their needs reliably and efficiently.</p>
                </div>
                <div class="skill-bar" data-percentage="75%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Developement</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                        <span class="down-arrow"></span>
                        </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="45%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Design</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                        <span class="down-arrow"></span>
                        </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="80%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Marketing</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                        <span class="down-arrow"></span>
                        </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="70%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Support</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                        <span class="down-arrow"></span>
                        </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="90%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Review</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                        <span class="down-arrow"></span>
                        </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-image">
                    <img src="assets/img/about/about.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Leveraging modern approaches to web development, we bring front-end, back-end, and architecture together to meet your business needs.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>Our suite of e-commerce technology solutions helps drive meaningful interactions with your customers and usher customer delight and loyalty. We specialize in Magento, Shopify and BigCommerce solutions.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>Before we release your product out into the world, everything must be in excellent working order and functioning flawlessly. That’s where our Quality Assurance shines.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Askde is a dynamic, versatile and full-service digital marketing agency that doesn’t rely on smoke and mirrors to attract new clients.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>We are also cognizant of technological and cost requirements and are equipped with the knowledge and experience to propose the best solutions for building mobile applications. Be it native, hybrid, or PWA applications, we have it covered!</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Support</h3>
                    <p>Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>

    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>

<section class="development-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="development-image">
                    <img src="assets/img/development.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="development-text">
                    <h3>Web & Mobile <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>With so many ways to build mobile apps, we have expertise in choosing what the best solution is for your mobile app development strategy, whether it's in Flutter, React Native, or native iOS and Android.</p>
                </div>
                <div class="development-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>UI/UX Design</h3>
                    <p>With a decade-long experience in UI/UX designing, our creative team of designers offers a full range of web and mobile app design services, from prototyping and graphic design to pixel-perfect UI.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-05dbcf">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Whether you are looking to develop a CMS, eCommerce or web application, we have a large web development team that creates secure and scalable web solutions tailored exclusively for your business goals.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-fec66f">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile Developement</h3>
                    <p>With so many ways to build mobile apps, we have expertise in choosing what the best solution is for your mobile app development strategy, whether it's in Flutter, React Native, or native iOS and Android.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-66a6ff">
                        <i class="flaticon-devices"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-section">
    <div class="container">
        <div class="video-content">
            <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
        </div>
    </div>
</div>

<section class="choose-section ptb-100" id="why-us">
    <div class="container">
        <div class="section-title">
            <h2>Why <span>Choose</span> Us</h2>
            <p>We transform daring ideas into exceptional products. Searching for a reliable partner that will take care of software development for you? You’ve come to the right place!. We create data-driven digital products that solve business challenges
                and user-focused apps.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Page Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Location Targeting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Latest <span>Projects</span></h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section pb-70">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>A social network app began working with Askdev in order to help them build their platform from the ground up.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Askdev developed and launched an Android and iOS app from scratch. Additionally, they provided free support for two months after the launch to ensure bugs and updates were handled smoothly.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I wanted to thank MindInventory team for a fantastic job they did on my app development project. The company has very high standards and did an incredible job.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2020</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2020</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->