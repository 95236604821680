<app-menu></app-menu>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Leveraging modern approaches to web development, we bring front-end, back-end, and architecture together to meet your business needs.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>Our suite of e-commerce technology solutions helps drive meaningful interactions with your customers and usher customer delight and loyalty. We specialize in Magento, Shopify and BigCommerce solutions.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>Before we release your product out into the world, everything must be in excellent working order and functioning flawlessly. That’s where our Quality Assurance shines.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Askde is a dynamic, versatile and full-service digital marketing agency that doesn’t rely on smoke and mirrors to attract new clients.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>We are also cognizant of technological and cost requirements and are equipped with the knowledge and experience to propose the best solutions for building mobile applications. Be it native, hybrid, or PWA applications, we have it covered!</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Support</h3>
                    <p>Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>

    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>

<!--<section class="faq-section pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">What access do I have on the free plan? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What access do I have on a free trial? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Does the price go up as my team gets larger? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How can I cancel my subscription? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can I pay via an Invoice? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>-->