<div class="navbar-area navbar-style-two bg-color">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-two" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-three" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-four" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Four</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-five" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Five</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-six" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Six</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-seven" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Seven</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-eight" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Eight</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a></li>

                    <li class="nav-item"><a routerLink="/services" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-two" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                        </ul>
                    </li>

                    <li class="nav-item"><a routerLink="/projects-one" class="nav-link">Projects <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects-one" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects One</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects-two" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects Two</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects details</a></li>
                        </ul>
                    </li>

                    <li class="nav-item"><a routerLink="/" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item"><a routerLink="/" class="nav-link">User</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
                                </ul>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>
                    </li>

                    <li class="nav-item"><a routerLink="/blog-grid" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <div class="others-options">
                    <div class="option-item"><i class="search-btn flaticon-search"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <a routerLink="/sign-up" class="default-btn">Sign Up</a>
                </div>
            </div>
        </nav>
    </div>
</div>