<app-menu></app-menu>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="conditions-section ptb-100">
    <div class="container">
        <section class="sec-space">
            <div class="container">
                <div class="holder-md">
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Information We Collect :</h2>
                        <ol class="mb-3 txt-16">
                            <li class="mb-3">Voluntary information provided by you through emails, or while subscribing to our blog, newsletter, or by filling out our contact form with your company information.</li>
                            <li class="no-m">Information gathered by web browsers like average time on site, IP address and location. We reserve the right to collect all the information made available to us by browsers.</li>
                        </ol>
                        <p class="txt-16 no-m">The information provided by you in Askdev website and those gathered via browsers are used only to serve you better and respond to your requests appropriately. Askdev does not rent or sell any of your information with third-parties.
                            Askdev will not share your information unless it is legally required.</p>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">How We Collect Information?</h2>
                        <h3><i>Cookies</i></h3>
                        <p class="txt-16 mb-3">We use cookies to track user activities on our website. In return, this information will help us in creating an enhanced experience for our website visitors. Cookies will be stored in your browser. You can remove or block cookies
                            using your browser settings. We refrain from storing any information that is considered sensitive.</p>
                        <h3><i>Google Analytics</i></h3>
                        <p class="txt-16 mb-3">We make use Google Analytics tool to know details like website traffic, page views, the device used, average time on site, and location. Analytics information divulges information on how you use our website and what you look out
                            for. It doesn’t tell us who you are. We use this information to create a better experience and show relevant information that aligns better with our visitors’ pulse. You have the option to stop being tracked anonymously using
                            these tips provided by Google.</p>
                        <h3><i>Tracking on social platforms</i></h3>
                        <p class="txt-16 no-m">We run ad campaigns on social platforms like Facebook, Twitter, and LinkedIn, and to track the results, we use the respective built-in analytics tools of these platforms. Through this, we will get to know your personal and contact
                            information like name, email id, designation, contact number, which will eventually help us show relevant ads to you in the future. You can use an ad blocker to prevent us from tracking your onsite behavior.</p>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Why We Collect Information? </h2>
                        <p class="txt-16">We collect information via the sources mentioned above to:</p>
                        <ol class="no-m txt-16">
                            <li>Market our services for which you have expressed interest</li>
                            <li>Provide support for services you have signed up for</li>
                            <li>Provide access to resources like Whitepapers and eBooks</li>
                        </ol>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Disclosure of User Information</h2>
                        <p class="txt-16 no-m">Askdev will never share, sell or rent personal information gathered from you with organizations or third-parties that are not a part of Askdev. The information will be shared only with persons (within Askdev) who are responsible
                            for responding to any service request or queries posted by you. If necessary, Askdev might share your information with its partners which follow the same privacy policy as ours to serve you better under strict confidentiality
                            agreements.
                        </p>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Data Protection Rights</h2>
                        <p class="txt-16">You can anytime request us to</p>
                        <ol class="mb-3 txt-16">
                            <li>Review the information that we have about you</li>
                            <li>Correct any wrong information about you</li>
                            <li>Delete your personal information</li>
                        </ol>
                        <p class="txt-16 no-m">You should also know that in particular cases, like in legal requirements, we may not be able to progress your request right away.</p>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Content Withdrawal</h2>
                        <ol class="mb-3 txt-16">
                            <li>You can, by all means, unsubscribe from our newsletters, events, alerts, and reports</li>
                            <li>You can stop your browser from storing cookies in your browser settings</li>
                            <li>You can delete cookies from your device at any time in your browser settings</li>
                        </ol>
                    </div>
                    <div class="mb-5">
                        <h2 class="text-uppercase txt-md mb-3">Links to Third-Party Sites</h2>
                        <p class="txt-16 no-m">Askdev’s website may have links that direct you to third-party websites. These links might have been provided for citation purposes. Askdev is not responsible for the authenticity of the information provided by them. These websites
                            might differ in privacy policy and have different cookie usage policy as well. Askdev does not support or endorse the privacy policy or terms of service of such external websites.</p>
                    </div>
                    <div class="">
                        <h2 class="text-uppercase txt-md mb-3">Privacy Policy Change</h2>
                        <p class="txt-16 no-m">Our Privacy Policy is subjected to frequent updates. Hence, we request visitors and users to check out our Privacy Policy section to get to know the latest changes rolled out. We also feel responsible to inform that any changes
                            made in privacy policy will take effect immediately once it is published in this space. For any queries surrounding our privacy policy.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>