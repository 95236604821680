<app-menu></app-menu>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/img/about/about-2.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>About Us</h2>
                    <div class="bar"></div>
                    <p>We’re able to serve our clients on a global scale while also looking to the future. From our Cloud Center of Excellence to our world-class design practice, we constantly seek out and develop exciting new ways to tackle complex problems.
                        Technology alone is not enough to succeed, every product needs a team of passionate people who will give it their best.</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our Vision</a></li>
                            <li><a href="#">Our Missinon</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>We paint the bright future for the businesses working on digital landscapes and firmly believe in providing productive long-term business relationships.</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>Handover contemporary and futuristic web and mobile software solutions to the businesses ranging from startups to enterprises with affordability and satisfaction.</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">What access do I have on the free plan? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What access do I have on a free trial? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Does the price go up as my team gets larger? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How can I cancel my subscription? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can I pay via an Invoice? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="clients-section pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>A social network app began working with Askdev in order to help them build their platform from the ground up.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Askdev developed and launched an Android and iOS app from scratch. Additionally, they provided free support for two months after the launch to ensure bugs and updates were handled smoothly.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I wanted to thank MindInventory team for a fantastic job they did on my app development project. The company has very high standards and did an incredible job.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>