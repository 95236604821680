<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home </a></li>

                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a></li>

                    <li class="nav-item"><a href="#why-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Why Choose Us</a></li>

                    <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services </a></li>

                    <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Solutions</a></li>

                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                    <!--<li class="nav-item"><a routerLink="/blog-grid" class="nav-link">Blog </a></li>-->

                </ul>
                <div class="others-options">
                    <div class="burger-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>